import {
  GET_PRIVATE_EQUITY_BANNERS,
  PRIVATE_EQUITY_BANNERS_LOADING,
} from "./Constants";

const initialState = {
  bannerLoading: false,
  banners: [],
};

export default function privateEquity(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case PRIVATE_EQUITY_BANNERS_LOADING:
      return {
        ...state,
        bannerLoading: true,
      };

    case GET_PRIVATE_EQUITY_BANNERS:
      return {
        ...state,
        banners: payload.data,
        bannerLoading: false,
      };

    default:
      return state;
  }
}
