import { Redirect } from "react-router-dom";
import api from "../../utils/api";
import { setAlert } from "../Alert/actions";
import io from "socket.io-client";
// import axios from 'axios'
import {
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGOUT,
  GET_USERS,
  GET_ME,
} from "./constants";

// Load User
export const loadUser = () => async (dispatch) => {
  try {
    const res = await api.get("/auth/me");

    dispatch({
      type: USER_LOADED,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Login User
export const login = (username, password) => async (dispatch) => {
  const body = { username, password };

  try {
    const res = await api.post("/auth/login", body);
    // console.log(res.data);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });
    dispatch(loadUser());
  } catch (err) {
    dispatch(setAlert("Please enter valid credentials", "info"));
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Logout
export const logout = () => (dispatch) => {
  <Redirect to="/" />;
  dispatch({
    type: LOGOUT,
  });
};

// Get me
export const getMe = () => async (dispatch) => {
  try {
    const res = await api.get(`/branch/me`);
    dispatch({
      type: GET_ME,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};
