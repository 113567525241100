import { GET_FOOTER } from "./Constants";

const initialState = {
  footer: {},
};

export default function footer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_FOOTER:
      return {
        ...state,
        footer: payload.data,
      };

    default:
      return state;
  }
}
