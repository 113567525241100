import { combineReducers } from "redux";
import sidebar from "./modules/Sidebar/reducer";
import auth from "./modules/User/reducer";
import alert from "./modules/Alert/reducer";
import aboutSar from "./modules/AboutSar/Reducer";
import strategic from "./modules/Strategic/Reducer";
import privateEquity from "./modules/PrivateEquity/Reducer";
import footer from "./modules/Footer/Reducer";

export default combineReducers({
  sidebar,
  auth,
  alert,
  aboutSar,
  strategic,
  privateEquity,
  footer,
});
