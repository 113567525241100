import {
  GET_ABOUT_SAR_BANNERS,
  ABOUT_SAR_BANNER_LOADING,
  ABOUT_SAR_TEAM_LOADING,
  GET_ABOUT_SAR_TEAM,
  GET_ABOUT_SAR_TEAM_HEADING,
} from "./Constants";

const initialState = {
  bannerLoading: false,
  banners: [],
  teamLoading: false,
  team: [],
  teamHeading: "",
};

export default function aboutSar(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ABOUT_SAR_BANNER_LOADING:
      return {
        ...state,
        bannerLoading: true,
      };

    case GET_ABOUT_SAR_BANNERS:
      return {
        ...state,
        banners: payload.data,
        bannerLoading: false,
      };

    case ABOUT_SAR_TEAM_LOADING:
      return {
        ...state,
        teamLoading: true,
      };

    case GET_ABOUT_SAR_TEAM:
      return {
        ...state,
        team: payload.data,
        teamLoading: false,
      };

    case GET_ABOUT_SAR_TEAM_HEADING:
      return {
        ...state,
        teamHeading: payload.data,
      };

    default:
      return state;
  }
}
