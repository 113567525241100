import { GET_STRATEGIC_BANNERS, STRATEGIC_BANNER_LOADING } from "./Constants";

const initialState = {
  bannerLoading: false,
  banners: [],
};

export default function strategic(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case STRATEGIC_BANNER_LOADING:
      return {
        ...state,
        bannerLoading: true,
      };

    case GET_STRATEGIC_BANNERS:
      return {
        ...state,
        banners: payload.data,
        bannerLoading: false,
      };

    default:
      return state;
  }
}
